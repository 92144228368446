import React, { Component } from 'react';
import Head from 'next/head'
import Image from 'next/image'
import Home from './home'
// import styles from '../styles/Home.module.css'
import { PAGEURL } from '../constants'
import { DefaultLayout } from '../layouts'
import { GoogleOAuthProvider } from '@react-oauth/google';
// import { logInit } from '../components/Logger'
// import SEO from '../components/common/SEO'
// import ReactGA from "react-ga";
// import '../styles/App.scss'
// ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS)

export class Index extends Component {
  render() {
    // const routes = [
    //   { path: PAGEURL.HOME, component: DefaultLayout },
    //   { path: PAGEURL.ABOUT, component: DefaultLayout },
    //   { path: PAGEURL.BULKING, component: DefaultLayout },
    //   { path: PAGEURL.BARBELL, component: DefaultLayout },
    //   { path: PAGEURL.PRODUCT_PICKER(':slug',':question'), component: DefaultLayout },
    //   { path: PAGEURL.SAVED_RESULTS, component: DefaultLayout },
    // ]
    // const pageRoutes = routes.map((e, i) =>
    //   <Route key={i} exact strict path={e.path} component={e.component} />
    // )

    return (
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH}>
        <div className="App">
          <Home/>
        </div>
      </GoogleOAuthProvider>

    )
  }
}

export default Index;
